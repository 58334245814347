import React, { useState, useEffect, ChangeEvent } from 'react';
import logo from './GraviLinkFull.svg';
import './App.css';

function App_ru() {
  const [inputValues, setInputValues] = useState<Record<string, string>>({});
  const [result, setResult] = useState('');

  const questions = [
    "Пол", 
    "Возраст", 
    "История болезни", 
    "Жалобы", 
    "Результаты осмотра",
  ];

  const sendAnalyzes = async () => {
    setResult("Ожидание...");
    try {
      const response = await fetch('/get_prediction', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...inputValues
        })
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const result = await response.text();
    setResult("Диагноз: " + result);
    } catch (error) {
      console.error('Ошибка:', error);
      setResult('Диагноз: Неизвестный');
    }
  }
  
  const getRandomProtocol = async () => {
    setResult('');
    try {
        const response = await fetch('/get_random_protocol', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
        });
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const protocol = await response.json();
        setResult("Диагноз: " + protocol.Result);

        setInputValues(protocol);
    } catch (error) {
        console.error('Error:', error);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value
    });
  };

  function handleFetchRandomProtocol() {
    getRandomProtocol();
  }

  function updateTextAreas() {
    const answers = document.getElementsByClassName("answer");
    Array.from(answers).forEach (answer => {
      const textArea = answer as HTMLTextAreaElement;
      textArea.value = inputValues[textArea.name] || '';
      changeTextareaHeight(textArea);
    })
  }
  
  function handleClear() {
    setResult('');
    setInputValues(
      questions?.reduce((obj: Record<string, string>, question) => {
        obj[question] = '';
        return obj;
      }, {}) || {}
    );
  };

  function changeTextareaHeight(element: HTMLTextAreaElement) {
    element.style.height = "1px";
    element.style.height = (element.scrollHeight) + "px";
  }

  // TODO: such updates may slow you when you type
  // Remove after getting rid of "loadRandom" button
  updateTextAreas();

  window.onload=updateTextAreas;
  window.onresize=updateTextAreas;

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"/>
        <div className='info'>
      <div className='buttons'>
		  <button className='rnd'   onClick={handleFetchRandomProtocol}>Случайный протокол</button>
		  <button className='clear' onClick={handleClear}>Очистить</button>
      </div>
      <div id="questions" className='questions'>
        {questions && questions.map((question) => (
        <div key={question} className='text-with-label'>
          <label htmlFor={question}>{question}</label>
          <textarea 
            id={question} 
            name={question} 
            className='answer' 
            onInput={(event) => {changeTextareaHeight(event.target as HTMLTextAreaElement); if(result != '') setResult('')}} 
            onChange={handleInputChange}
          />
        </div>
        ))}
      </div>
      <div>
		    <button onClick={sendAnalyzes}>Предсказать</button>
        <div className='prediction'>{result}</div>
      </div>
      </div>
      </header>
    </div>
  );
}

export default App_ru;
